
import { CMSBannerController } from "@/app/ui/controllers/CMSBannerController";
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { BannerConfigData } from "@/domain/entities/BannerConfig";
import { CreateBannerConfigRequest } from "@/data/payload/api/CMSBannerRequest";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import parsingErrorResponse from "@/app/infrastructures/misc/common-library/ParsingErrorResponse";
import { ApiStateData } from "@/domain/entities/Api";

@Options({
  components: {
    DetailLayout,
    UploadFile
  }
})
export default class UpdateCreateCMSBanner extends Vue {
  beforeMount() {
    this.validatePage();
  }

  mounted() {
    if (this.pageType === "update") {
      this.fetchDetail();
    }
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  apiDetailData = new ApiStateData();
  detailData = new BannerConfigData();

  async fetchDetail() {
    try {
      this.apiDetailData.loading = true;
      this.detailData = await CMSBannerController.getBannerDetail(this.id);
      this.apiDetailData.errorType = "";
    } catch (error) {
      this.apiDetailData.errorType = parsingErrorResponse(error).type;
    } finally {
      this.apiDetailData.loading = false;
    }
  }

  pageType = "";
  successModalTitle = "Pembuatan banner berhasil!";
  successModalMessage = "Banner config yang anda buat berhasil tersimpan";
  validatePage() {
    const route = this.$route;
    if (route.path === `/cms-banner/create`) {
      this.pageType = "create";
    } else if (route.path === `/cms-banner/${route.params.id}/edit`) {
      this.pageType = "update";
      this.successModalTitle = "Perubahan berhasil disimpan!";
      this.successModalMessage = "Perubahan data yang anda lakukan berhasil. ";
    } else {
      this.goBack();
    }
  }

  goBack() {
    this.$router.back();
  }

  onUpdateStatus() {
    this.detailData.isActive = !this.detailData.isActive;
  }

  // Date
  invalidStartDate = false;
  validateDateStartEnd() {
    const dateStart = new Date(this.detailData.startDate);
    const dateEnd = new Date(this.detailData.endDate);
    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    );
    if (this.pageType === "create") {
      this.invalidStartDate = dateStart.getTime() > dateEnd.getTime();
      return;
    }
    this.invalidStartDate =
      this.pageType === "update" && this.detailData.status !== "active"
        ? dateStart.getTime() < today.getTime()
        : false;
  }

  public setPeriodeStart(date: Date) {
    this.detailData.startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.validateDateStartEnd();
  }

  public setPeriodeEnd(date: any) {
    this.detailData.endDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
    this.validateDateStartEnd();
  }

  // Data
  targetListData: Array<string> = ["Internal", "Partner", "Client", "Customer"];

  priorityData = [
    { name: "Priority", value: "" },
    { name: "First", value: "first" },
    { name: "Last", value: "last" }
  ];

  get errorPriority() {
    return this.detailData.priority.length && +this.detailData.priority <= 0;
  }

  archiveFile: any = "";
  resetImageBanner() {
    this.detailData.image = "";
    this.archiveFile = "";
  }

  isInvalidImage = false;
  onFileChange() {
    const file = this.archiveFile;
    if (this.archiveFile) {
      this.detailData.image = URL.createObjectURL(file);
    }
    if (this.archiveFile === "" && this.detailData.image === "") {
      this.isInvalidImage = true;
    } else {
      this.isInvalidImage = false;
    }
  }

  get isFormValid() {
    return (
      this.detailData.image &&
      this.detailData.target.length &&
      !this.errorPriority &&
      this.detailData.priority &&
      this.detailData.name &&
      !this.invalidStartDate
    );
  }

  async onSubmit() {
    const payload = new CreateBannerConfigRequest({
      name: this.detailData.name,
      target: this.detailData.target.toString().toLowerCase(),
      priority: +this.detailData.priority,
      startDate: new Date(this.detailData.startDate).toLocaleDateString(
        "fr-CA"
      ),
      endDate: new Date(this.detailData.endDate).toLocaleDateString("fr-CA"),
      url: this.detailData.url,
      status: this.detailData.isActive ? "active" : "inactive",
      bannerUrl: this.archiveFile
    });
    try {
      MainAppController.showLoading();
      this.pageType === "create"
        ? await CMSBannerController.createBannerConfig(payload)
        : await CMSBannerController.updateBannerConfig({
            payload: payload,
            id: this.detailData.id
          });
      this.openSuccess = true;
    } catch (err) {
      MainAppController.showErrorMessage(
        parsingErrorResponse(
          err,
          this.pageType === "update"
            ? "Perubahan Banner Gagal !"
            : "Pembuatan Banner Gagal !",
          () => this.onSubmit()
        )
      );
    } finally {
      MainAppController.closeLoading();
    }
  }

  openSuccess = false;

  onCloseSuccess() {
    this.openSuccess = false;
    this.goBack();
  }
}
